<template>
    <div id="myaccount" class="myaccount container-fluid secure" >

        <h1 class="page-header primary">My Account</h1>
        <!-- <span @click="copySupportInfoToClipboard" class="link blue underline-hover hover-dark-blue">Copy to Clipboard</span> -->

    <!-- <router-link to="/updateaccount">Edit Account Information</router-link> -->
    <div><span v-if="true" @click="goToEditAccount" class="link blue underline-hover hover-dark-blue">Edit Account Information</span></div>

    <div v-if="whoami" class="table-responsive table--no-card m-b-40">
        <table class="table table-borderless table-striped">

            <tbody>
                <tr>
                    <td>Name</td>
                    <td>{{ whoami.Name }}</td>
                </tr>
                <tr>
                    <td>Email</td>
                    <td>{{ whoami.Email }}</td>
                </tr>
                <tr>
                    <td>ID</td>
                    <td>{{ whoami.ID }}</td>
                </tr>
                <tr>
                    <td>Owner ID</td>
                    <td>{{ whoami.OwnerID }}</td>
                </tr>
                <tr>
                    <td>My Roles</td>
                    <td>
                      <!-- Using a workaround because /users call doesn't return roles properly -->
                        <!-- <div v-for="role in whoami.Roles" :key="role"> -->
                        <div v-for="role in getSessionObj.roles" :key="role">
                          {{ role }}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    </div>
</template>

<script>
import Helpers from "@/helpers.js";
import { mapGetters } from 'vuex';

export default {
  name: "MyAccount",
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(['getUsers', 'getSessionObj']),
    whoami() {
      let myID = this.getSessionObj.userID;
      let user = this.getUsers.find(u => u.ID === myID);
      return user ? user : {};
    },
  },
  methods: {
    goToEditAccount() {
      // named route
      this.$router.push({
        name: "updateaccount",
        params: {
          from: "myaccount",
          id: this.whoami.ID,
          name: this.whoami.Name,
          email: this.whoami.Email
        }
      });

      // with query, resulting in /register?plan=private
      //this.$router.push({ path: 'updateaccount', query: { id: this.whoami.id }})
    },
  },

  created: function() {
    Helpers.doViewCreation(this);

  },
  mounted: function() {

  },
  components: {}
};
</script>

<style scoped>
</style>
